import React from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

// eslint-disable-next-line import/no-cycle
import usePageContext from 'utils/hooks/usePageContext'; // TODO: Fix this dependency cycle issue
import { getProductionTitleWorkNames } from 'utils/productions';
import { ENTITY_TYPE, BASE_PAGE_ROUTES } from 'constants/index';

import DatesCount from '../DatesCount';
import classes from './ProductionPeriod.module.scss';

const ProductionPeriod = ({ showArtistPerformedDates, production, profile, isHovered, trackingData }) => {
  const { baseRoute, navigate, entityType } = usePageContext();
  const viewMode = entityType === ENTITY_TYPE.ARTIST && baseRoute === BASE_PAGE_ROUTES.CASTING;
  const productionLinkProps = navigate.getLinkProps({ entity: production, entityType: ENTITY_TYPE.PRODUCTION });
  const name = getProductionTitleWorkNames(production);
  const showRedMask = production?.contributions?.find(contributor => contributor?.profile?.id === profile?.id)
    ?.isRedMasked;

  const title = name?.customName || name?.workNames?.[0];

  return (
    <div
      className={classnames(classes.period, {
        [classes.period__multiRole]: !!showArtistPerformedDates,
      })}
    >
      <LinkButton
        styles={{
          root: classnames(classes.period__link, { [classes.mwLink]: !!showArtistPerformedDates }),
          icon: classes.mask,
        }}
        rightIcon={<SpriteIcon icon={showRedMask ? 'red_mask' : 'grey_mask'} size={14} className={classes.icon} />}
        variant="text"
        isLink
        title={title}
        target="_blank"
        trackingData={{
          ...trackingData,
          subComponent: SUB_COMPONENTS.DATE,
          entityId: production?.id,
          entityType: ENTITY_TYPE.PRODUCTION,
        }}
        {...productionLinkProps}
      >
        <Typography color="inherit" size="12">
          {production?.periode}
        </Typography>
      </LinkButton>
      {showArtistPerformedDates && profile && viewMode && (
        <DatesCount production={production} profile={profile} isHovered={isHovered} />
      )}
    </div>
  );
};

export default ProductionPeriod;
